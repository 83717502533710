import { Component, HostListener, ViewChild } from "@angular/core";
import { MatSidenav, MatSidenavModule } from "@angular/material/sidenav";
import { MatListModule } from "@angular/material/list";
import { MatIconModule } from "@angular/material/icon";
import { ModeService } from "@cq/app/core/environment/mode.service";
import { DialogService } from "@cq/app/shared/dialog/services/dialog.service";
import { PageUpgradeDialogComponent } from "../page-upgrade-dialog/page-upgrade-dialog.component";
import { Router } from "@angular/router";
import { IconModule } from "@cq/app/shared/icon/icon.module";
import { SettingsDialogComponent } from "../settings-dialog/settings-dialog.component";

@Component({
  selector: "cq-tools-sidenav",
  imports: [IconModule, MatIconModule, MatListModule, MatSidenavModule],
  templateUrl: "./tools-sidenav.component.html",
  styleUrl: "./tools-sidenav.component.scss",
})
export class ToolsSidenavComponent {
  @ViewChild(MatSidenav) sidenav!: MatSidenav;

  private toolActive = false;

  constructor(
    private readonly mode: ModeService,
    private readonly dialog: DialogService,
    private readonly router: Router,
  ) {}

  @HostListener("window:keydown.alt.shift.t", ["$event"])
  toggle(event: KeyboardEvent) {
    this.guard(() => {
      event.preventDefault();
      this.sidenav.toggle();
    });
  }

  @HostListener("window:keydown.alt.shift.u", ["$event"])
  configuration(event?: KeyboardEvent) {
    this.sidenav.close();
    this.guard(() => {
      event?.preventDefault();
      this.toolActive = true;
      this.dialog
        .open(PageUpgradeDialogComponent)
        .afterClosed()
        .subscribe(() => (this.toolActive = false));
    });
  }

  @HostListener("window:keydown.alt.shift.m", ["$event"])
  mockup(event?: KeyboardEvent) {
    this.sidenav.close();
    this.guard(() => {
      event?.preventDefault();
      this.toolActive = true;
      this.router
        .navigate(["upgrade/mockup"])
        .then(() => (this.toolActive = false));
    });
  }

  @HostListener("window:keydown.alt.shift.s", ["$event"])
  settings(event?: KeyboardEvent) {
    this.sidenav.close();
    this.guard(() => {
      event?.preventDefault();
      this.toolActive = true;
      this.dialog
        .open(SettingsDialogComponent)
        .afterClosed()
        .subscribe(() => (this.toolActive = false));
    });
  }

  private guard(callback: () => unknown) {
    if (this.toolActive) {
      return;
    }

    this.mode.guard(callback);
  }
}

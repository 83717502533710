import StateManager from "./state-manager.service";

export class LegacyAccountNavigationService {
  /* @ngInject */
  constructor(private readonly AEStateManager: StateManager) {}

  isAccountActive() {
    return this.AEStateManager.current.isAccount();
  }
}

import { Injectable, signal } from "@angular/core";
import { Building } from "../models/building";

@Injectable({
  providedIn: "root",
})
export class BusinessownersBuildingStoreService {
  #buildings = signal<Building[]>([]);
  buildings = this.#buildings.asReadonly();

  setBuildings(buildings: Building[]) {
    this.#buildings.set(buildings);
  }

  removeBuilding(removeBuilding: Building) {
    this.#buildings.update((buildings) => {
      return buildings.filter((building) => building.id !== removeBuilding.id);
    });
  }
}

import { StateService } from "@uirouter/core";

export class LegacyBusinessownersBuildingNavigationService {
  /* @ngInject */
  constructor(private readonly $state: StateService) {}

  navigateToAddress(jobNumber?: string, buildingPublicId?: string) {
    if (buildingPublicId) {
      return this.$state
        .go("ae.job.bop.building.edit.address", { jobNumber, buildingPublicId })
        .then(() => true);
    } else {
      return this.$state
        .go("ae.job.bop.building.add.address", { jobNumber })
        .then(() => true);
    }
  }

  navigateToDetails(jobNumber?: string, buildingPublicId?: string) {
    if (!jobNumber || !buildingPublicId) {
      return Promise.resolve(false);
    }

    return this.$state
      .go("ae.job.bop.building.edit.classification", {
        jobNumber,
        buildingPublicId,
        isFromReviewQuote: true, // opens the details container
      })
      .then(() => true);
  }
}

import { isEmpty } from "lodash";
import logger from "@clearquote/common/logger";
import { environment } from "@cq/environments/environment";
import { User } from "../user/models/user";

/*
  Generic WalkMe initialization to allow for provided configuration, fashioned after provided initialization scripts.

  (function () {
    var walkme = document.createElement("script");
    walkme.type = "text/javascript";
    walkme.async = true;
    walkme.src =
      "https://cdn.walkme.com/users/0b14fcbbcf5f428da7aea21f8f51d399/test/walkme_0b14fcbbcf5f428da7aea21f8f51d399_https.js";

    var s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(walkme, s);
    window._walkmeConfig = { smartLoad: true };
  })();
*/
export function init(url: string) {
  if (!environment.isWalkMeEnabled || isEmpty(url)) {
    logger.warn(
      "WalkMe integration disabled, or not configured. Bypassing initialization.",
    );
    return;
  }

  const walkme = document.createElement("script");
  walkme.type = "text/javascript";
  walkme.async = true;
  walkme.src = url;

  const s = document.getElementsByTagName("script")[0];
  s.parentNode?.insertBefore(walkme, s);
  window._walkmeConfig = { smartLoad: true };
}

export function setUserProperties(user: User) {
  // Set the logged in user type for WalkMe
  window.loggedInUserType_Ext = user.isExternal ? "External" : "Internal";

  // Set the userid for WalkMe
  window.loggedInUserId_Ext = user.id;

  // Set the user roles for WalkMe
  window.loggedInUserRoles_Ext = user.roles.toString();
}

<cq-dialog>
  <cq-icon-font cq-dialog-icon name="rule"></cq-icon-font>
  <cq-dialog-title>Page Upgrade Status</cq-dialog-title>
  <cq-dialog-content>
    <table
      mat-table
      [dataSource]="upgradeDataSource"
      class="cq-table"
      aria-label="page upgrade status"
    >
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef class="page-title">Page Title</th>
        <td mat-cell *matCellDef="let page" class="page-title">
          {{ page.title }}
        </td>
      </ng-container>

      <ng-container matColumnDef="configured">
        <th mat-header-cell *matHeaderCellDef>Configuration</th>
        <td mat-cell *matCellDef="let page">
          @if (page.configured) {
            <cq-icon-font
              class="configuration-enabled"
              name="check_circle"
            ></cq-icon-font>
          } @else {
            <cq-icon-font
              class="configuration-disabled"
              name="block"
            ></cq-icon-font>
          }
        </td>
      </ng-container>

      <ng-container matColumnDef="override">
        <th mat-header-cell *matHeaderCellDef>Override</th>
        <td mat-cell *matCellDef="let page">
          <mat-slide-toggle
            id="{{ page.prefix | overrideId }}"
            [(ngModel)]="page.overridden"
          ></mat-slide-toggle>
        </td>
      </ng-container>

      <ng-container matColumnDef="user">
        <th mat-header-cell *matHeaderCellDef>User</th>
        <td mat-cell *matCellDef="let page">
          @if (page.overridden) {
            <mat-slide-toggle
              id="{{ page.prefix | userId }}"
              [(ngModel)]="page.user"
            ></mat-slide-toggle>
          }
        </td>
      </ng-container>

      <ng-container matColumnDef="current">
        <th mat-header-cell *matHeaderCellDef>Current</th>
        <td mat-cell *matCellDef="let page">
          @if (page.current) {
            <cq-icon-font
              class="configuration-enabled"
              name="check_circle"
            ></cq-icon-font>
          } @else {
            <cq-icon-font
              class="configuration-disabled"
              name="block"
            ></cq-icon-font>
          }
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="upgradeColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: upgradeColumns"></tr>
    </table>
  </cq-dialog-content>
  <cq-dialog-actions>
    <cq-button (click)="cancel()">
      <cq-icon-font name="cancel"></cq-icon-font>
      Cancel
    </cq-button>
    <cq-dialog-action-spacer></cq-dialog-action-spacer>
    <cq-button (click)="save()">
      <cq-icon-font name="check"></cq-icon-font>
      Save
    </cq-button>
  </cq-dialog-actions>
</cq-dialog>

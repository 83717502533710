import { inject, Injectable } from "@angular/core";
import { Observable, combineLatest, map, tap } from "rxjs";
import { GatewayUserService } from "../../edge/gateway/gateway-user.service";
import {
  SystemDateService,
  alignPolicyCenterISODate,
} from "../../environment/services/system-date.service";
import { UserPermissionRepositoryService } from "./user-permission-repository.service";
import { User } from "../models/user";

@Injectable({
  providedIn: "root",
})
export class UserRepositoryService {
  #gatewayUser = inject(GatewayUserService);
  #systemDate = inject(SystemDateService);
  #permission = inject(UserPermissionRepositoryService);

  retrieveCurrentUser(): Observable<User> {
    return combineLatest([
      this.#gatewayUser.getCurrentUser(),
      this.#permission.verifyPermissions([
        "edit_remarketing_ext",
        "edit_service_plus_account_ext",
      ]),
    ]).pipe(
      tap(([user]) => {
        /*
            System time is currently provided by PolicyCenter as an ISO 8601 formatted date time string.
            Unfortunately, PolicyCenter is also setup to define all of it internal time as EST, with
            effective boundaries being down to one minute after midnight EST. This puts ClearQuote in an
            awkward scenario where our definition of date needs to be PolicyCenter centric in order to
            have a common understanding of effectiveness and other such date calculations.

            I'm not a fan of this complexity, but the only way I can currently see going forward with a
            consistent understanding of date ranges is to establish "today" in UTC, but a realigned "offset"
            UTC to align with PolicyCenter's EST expectations.
          */
        this.#systemDate.setSystemDate(alignPolicyCenterISODate(user.sysDate));
      }),
      map(([user, permissions]) => {
        return {
          displayName: user.displayName,
          email: user.email,
          firstName: user.firstName,
          group: user.groupName_Ext,
          helpDeskNumber: user.helpDeskNumber_Ext,
          id: user.userID,
          isActive: true,
          isExternal: user.isUserExternal,
          lastName: user.lastName,
          permissions: permissions,
          roles: user.roles ?? [],
          type: user.userType_Ext,
        };
      }),
    );
  }
}

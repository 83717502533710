import { Injectable, computed, inject } from "@angular/core";
import { Router } from "@angular/router";
import { BUSINESSOWNERS } from "@cq/app/core/model/product";
import { EXACT, PARTIAL } from "@cq/app/core/routing/match-option";
import { RoutingService } from "@cq/app/core/routing/services/routing.service";
import { map } from "rxjs";
import { PolicyTransactionStoreService } from "@cq/app/policy/services/policy-transaction-store.service";
import { BusinessownersBuildingStoreService } from "../stores/businessowners-building-store.service";
import { PolicyTransaction } from "@cq/app/policy/models/policy";

@Injectable({
  providedIn: "root",
})
export class BopNavigationService {
  #buildingStore = inject(BusinessownersBuildingStoreService);

  private transaction = computed(() => {
    return this.transactionStore
      .transactions()
      .find((transaction) => transaction.product === BUSINESSOWNERS);
  });
  private policyTransactionId = computed(() => this.transaction()?.id);
  hasBOP = computed(() => this.transaction() !== undefined);

  isBOPActive$ = this.routing.navigationChanges$.pipe(
    map(() => this.isBOPActive()),
  );
  isBOPInformationActive$ = this.routing.navigationChanges$.pipe(
    map(() => this.isBOPInformationActive()),
  );
  isBOPCoveragesActive$ = this.routing.navigationChanges$.pipe(
    map(() => this.isBOPCoveragesActive()),
  );

  // BOP Coverages is only available once there is a building associated to the BOP product
  isBOPCoveragesAvailable = computed(
    () => this.#buildingStore.buildings().length > 0,
  );

  constructor(
    private readonly router: Router,
    private readonly routing: RoutingService,
    private readonly transactionStore: PolicyTransactionStoreService,
  ) {}

  isBOPActive() {
    return this.router.isActive(
      `${this.routePrefix()}/businessowners`,
      PARTIAL,
    );
  }

  isBOPInformationActive() {
    return this.router.isActive(
      `${this.routePrefix()}/businessowners/information`,
      EXACT,
    );
  }

  isBOPCoveragesActive() {
    return this.router.isActive(
      `${this.routePrefix()}/businessowners/coverages`,
      EXACT,
    );
  }

  navigateToBOPInformation(transaction?: PolicyTransaction) {
    return this.router.navigate([
      `${this.routePrefix(transaction)}/businessowners/information`,
    ]);
  }

  navigateToBOPCoverages() {
    return this.router.navigate([
      `${this.routePrefix()}/businessowners/coverages`,
    ]);
  }

  private routePrefix(transaction?: PolicyTransaction) {
    const policyTransactionId = transaction?.id ?? this.policyTransactionId();
    return `/upgrade/policy-transaction/${policyTransactionId}`;
  }
}

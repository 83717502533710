import { StateService } from "@uirouter/core";
import StateManager from "../common/navigation/state-manager.service";

export class LegacyBusinessownersNavigationService {
  /* @ngInject */
  constructor(
    private readonly $state: StateService,
    private readonly AEStateManager: StateManager,
  ) {}

  isBusinessownersActive() {
    return this.AEStateManager.current.isBOP();
  }

  isBusinessownersCoveragesActive() {
    return this.AEStateManager.current.isBOPCoverages();
  }

  navigateToBusinessownersInformation(jobNumber: string) {
    return this.$state
      .go("ae.job.bop.bop-info", {
        jobNumber,
      })
      .then(() => true);
  }

  navigateToBusinessownersCoverages(jobNumber: string) {
    return this.$state
      .go("ae.job.bop.bop-coverages", {
        jobNumber,
      })
      .then(() => true);
  }
}

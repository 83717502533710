import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { filter, map, startWith } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class RoutingService {
  navigationChanges$ = this.router.events.pipe(
    filter((e): e is NavigationEnd => e instanceof NavigationEnd),
    map((e) => e.id),
    startWith(this.router.getCurrentNavigation()?.id),
  );

  constructor(private readonly router: Router) {}
}

import { inject, Injectable } from "@angular/core";
import {
  Page,
  PageUpgradeService,
} from "@cq/app/core/environment/services/page-upgrade.service";
import { PolicyNavigationRouter } from "@cq/app/policy/navigation/policy-navigation-router";
import { LegacyBusinessownersNavigationService } from "@legacy/nationwide/js/src/edge/quoteandbind/bop/bop-navigation.service";
import { BopNavigationService } from "./bop-navigation.service";

export function legacyBusinessownersNavigationFactory(
  $injector: angular.auto.IInjectorService,
) {
  return $injector.get<LegacyBusinessownersNavigationService>(
    "CQBusinessownersNavigation",
  );
}

export const legacyBusinessownersNavigationProvider = {
  provide: LegacyBusinessownersNavigationService,
  useFactory: legacyBusinessownersNavigationFactory,
  deps: ["$injector"],
};

@Injectable({
  providedIn: "root",
})
export class BopNavigationRouterService implements PolicyNavigationRouter {
  #legacyBusinessownersNavigation = inject(
    LegacyBusinessownersNavigationService,
  );
  #pageUpgrade = inject(PageUpgradeService);
  #bopNavigation = inject(BopNavigationService);

  isBusinessownersActive() {
    return (
      this.#legacyBusinessownersNavigation.isBusinessownersActive() ||
      this.#bopNavigation.isBOPActive()
    );
  }

  navigateToBusinessownersInformation(policyTransactionId: string) {
    if (this.#pageUpgrade.isUpgraded(Page.BOP_INFORMATION)) {
      return this.#bopNavigation.navigateToBOPInformation();
    } else {
      return this.#legacyBusinessownersNavigation.navigateToBusinessownersInformation(
        policyTransactionId,
      );
    }
  }

  navigateToBusinessownersCoverages(policyTransactionId: string) {
    return this.#legacyBusinessownersNavigation.navigateToBusinessownersCoverages(
      policyTransactionId,
    );
  }

  navigateToPolicyStart(policyTransactionId: string): Promise<boolean> {
    return this.navigateToBusinessownersInformation(policyTransactionId);
  }

  navigateToPolicyEnd(policyTransactionId: string): Promise<boolean> {
    return this.navigateToBusinessownersCoverages(policyTransactionId);
  }
}

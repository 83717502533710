import { Injectable, computed, signal } from "@angular/core";
import { ReplaySubject, map, startWith } from "rxjs";
import { Account } from "../models/account";
import { NamedInsured } from "../models/named-insured";

@Injectable({
  providedIn: "root",
})
export class AccountStoreService {
  #account = signal<Account | undefined>(undefined);
  #accountNumber = signal<string | undefined>(undefined);

  private accountSubject = new ReplaySubject<Account>(1);
  readonly account$ = this.accountSubject.asObservable();
  readonly account = this.#account.asReadonly();

  readonly accountNumber = computed(() => {
    const accountNumber = this.#accountNumber();
    const account = this.#account();
    return accountNumber ?? account?.accountNumber;
  });

  readonly isCreated = computed(() => {
    const account = this.account();
    return account?.accountNumber !== undefined;
  });
  readonly isCreated$ = this.account$.pipe(
    map((account) => account.accountNumber !== undefined),
    startWith(false),
  );

  set(account: Account) {
    this.#account.set(account);
    this.accountSubject.next(account);
  }

  setAccountNumber(accountNumber: string) {
    this.#accountNumber.set(accountNumber);
  }

  updateAccountHolder(namedInsured: NamedInsured) {
    this.#account.update((account) => {
      if (!account) {
        return;
      }

      return { ...account, firstNamedInsured: namedInsured };
    });
  }

  updateKentuckyTaxIndicator(indicator: boolean) {
    this.#account.update((account) => {
      if (!account) {
        return;
      }

      return { ...account, isKentuckyTaxAcknowledged: indicator };
    });
  }
}

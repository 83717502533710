import { Injectable, computed, signal } from "@angular/core";
import { DateTime } from "luxon";

export function alignPolicyCenterISODate(iso: string) {
  const zoned = DateTime.fromISO(iso).setZone("US/Eastern");
  return DateTime.utc(zoned.year, zoned.month, zoned.day);
}

@Injectable({
  providedIn: "root",
})
export class SystemDateService {
  /*
    The following PolicyCenter script parameters seemingly play a hand in various
    system date calculations. I'm documenting these as a current state snapshot
    to assist in any future efforts to pull these values from PolicyCenter rather
    than hardcoding them in ClearQuote.

    MaxDaysToBackDateAccountExternal_Ext - -5
    MaxDaysToBackDateAccountInternal_Ext - -90

    MaxDaysToFutureDateAccountAllUsers_Ext - 120
    MaxDaysToFutureDatePolicyAllUsers_Ext - 120

    MaxDaysToFutureDays - 90

    MaxDaysUntilQuoteExpiresExternal_Ext - -10
    MaxDaysUntilQuoteExpiresInternal_Ext - -90
  */

  // start with current utc date, await an update based on system time from PolicyCenter
  private systemDate = signal(DateTime.utc().startOf("day"));

  current = this.systemDate.asReadonly();

  minimumExternalAccountEffectiveDate = computed(() => this.current());
  minimumInternalAccountEffectiveDate = computed(
    () => this.current().minus({ days: 90 }), // MaxDaysToBackDateAccountInternal_Ext
  );

  maximumQuotableEffectiveDate = computed(() =>
    this.current().plus({ days: 90 }),
  );
  maximumAccountEffectiveDate = computed(
    () => this.current().plus({ days: 120 }), // MaxDaysToFutureDateAccountAllUsers_Ext
  );

  setSystemDate(systemDate: DateTime) {
    this.systemDate.set(systemDate.startOf("day"));
  }
}

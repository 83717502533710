import { Injectable, signal } from "@angular/core";
import { PolicyLocation } from "../models/location";

@Injectable({
  providedIn: "root",
})
export class BusinessownersLocationStoreService {
  #locations = signal<PolicyLocation[]>([]);
  locations = this.#locations.asReadonly();

  getLocation(id: string) {
    return this.#locations().find((location) => location.id === id);
  }

  set(location: PolicyLocation): void;
  set(locations: PolicyLocation[]): void;
  set(locations: PolicyLocation[] | PolicyLocation): void {
    const updates = Array.isArray(locations) ? locations : [locations];
    this.#locations.update((locations) => {
      const updatedIds = updates.map((u) => u.id);
      const filtered = locations.filter(
        (location) => !updatedIds.includes(location.id),
      );
      return [...filtered, ...updates];
    });
  }

  setLocations(locations: PolicyLocation[]) {
    this.#locations.set(locations);
  }
}

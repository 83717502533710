import { Component, inject } from "@angular/core";
import { SystemService } from "@cq/app/core/system/services/system.service";
import { ButtonModule } from "@cq/app/shared/button/button.module";
import { DialogModule } from "@cq/app/shared/dialog/dialog.module";
import { Dialog } from "@cq/app/shared/dialog/model/dialog";
import { IconModule } from "@cq/app/shared/icon/icon.module";
import { delay } from "rxjs";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

const TIMEOUT = 5 * 60 * 1000; // 5 minutes

interface Result {
  continue: boolean;
}

@Component({
  selector: "cq-idle-dialog",
  imports: [ButtonModule, DialogModule, IconModule],
  templateUrl: "./idle-dialog.component.html",
  styleUrl: "./idle-dialog.component.scss",
})
export class IdleDialogComponent extends Dialog<IdleDialogComponent, Result> {
  #system = inject(SystemService);

  constructor() {
    super();

    this.dialog
      .afterOpened()
      .pipe(delay(TIMEOUT), takeUntilDestroyed())
      .subscribe(() => {
        this.dialog.close({ continue: false });
        this.#system.exit();
      });
  }

  continue() {
    this.dialog.close({ continue: true });
  }
}

import {
  computed,
  inject,
  Injectable,
  InjectionToken,
  signal,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { DialogService } from "@cq/app/shared/dialog/services/dialog.service";
import {
  debounceTime,
  filter,
  fromEvent,
  merge,
  startWith,
  switchMap,
  tap,
} from "rxjs";
import { IdleDialogComponent } from "../components/idle-dialog/idle-dialog.component";
import { SettingsService } from "../../environment/services/settings.service";

const EVENTS = [
  "keydown",
  "mousedown",
  "mousemove",
  "touchmove",
  "touchstart",
  "scroll",
];

export interface Configuration {
  idleTime: number;
}

export const IDLE_CONFIGURATION = new InjectionToken<Configuration>(
  "IDLE_CONFIGURATION",
);

@Injectable({
  providedIn: "root",
})
export class IdleService {
  #configuration = inject(IDLE_CONFIGURATION);
  #dialog = inject(DialogService);
  #settings = inject(SettingsService);

  #activated = signal(true);
  #active = computed(
    () => this.#settings.isIdleTimeoutEnabled() && this.#activated(),
  );

  constructor() {
    merge(...EVENTS.map((event) => fromEvent(window, event)))
      .pipe(
        startWith(new Event("idle-start")),
        debounceTime(this.#configuration.idleTime),
        filter(() => this.#active()),
        tap(() => this.deactivate()),
        switchMap(() => this.#dialog.prompt(IdleDialogComponent)),
        takeUntilDestroyed(),
      )
      .subscribe((result) => {
        if (result?.continue) {
          this.activate();
        }
      });
  }

  activate() {
    this.#activated.set(true);
  }

  deactivate() {
    this.#activated.set(false);
  }
}

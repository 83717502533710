import { computed, inject, Injectable, signal } from "@angular/core";
import { map } from "rxjs";
import { BopBuildingNavigationService } from "../navigation/bop-building-navigation.service";
import { BusinessownersBuildingStoreService } from "../../stores/businessowners-building-store.service";
import { BusinessownersLocationStoreService } from "../../stores/businessowners-location-store.service";
import { toObservable } from "@angular/core/rxjs-interop";

export enum BuildingFlow {
  ADD = "ADD",
  EDIT = "EDIT",
}

@Injectable()
export class BopActiveBuildingService {
  #buildingStore = inject(BusinessownersBuildingStoreService);
  #locationStore = inject(BusinessownersLocationStoreService);
  #navigation = inject(BopBuildingNavigationService);

  #activeBuildingId = signal<string | undefined>(undefined);

  building = computed(() => {
    const buildingId = this.#activeBuildingId();
    return this.#buildingStore
      .buildings()
      .find((building) => building.id === buildingId);
  });
  building$ = toObservable(this.building);

  location = computed(() => {
    const building = this.building();
    return this.#locationStore
      .locations()
      .find((location) => location.id === building?.locationId);
  });

  navigator$ = this.building$.pipe(
    map((building) => this.#navigation.navigator(building, this.flow)),
  );

  flow = BuildingFlow.ADD;

  activate(buildingId?: string) {
    this.#activeBuildingId.set(buildingId);
  }
}

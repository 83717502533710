import { Injectable, inject } from "@angular/core";
import { tap } from "rxjs";

import { UserRepositoryService } from "../repositories/user-repository.service";
import { UserStoreService } from "../stores/user-store.service";

@Injectable({
  providedIn: "root",
})
export class UserService {
  private readonly repository = inject(UserRepositoryService);
  private readonly store = inject(UserStoreService);

  current = this.store.current;
  isExternal = this.store.isExternal;
  isInternal = this.store.isInternal;

  retrieveCurrentUser() {
    return this.repository
      .retrieveCurrentUser()
      .pipe(tap((user) => this.store.setCurrentUser(user)));
  }
}

import { Injectable, inject } from "@angular/core";
import { LegacyAccountNavigationService } from "@legacy/nationwide/js/src/edge/quoteandbind/common/navigation/account-navigation.service";
import { AccountNavigationService } from "./account-navigation.service";
import { Params } from "@angular/router";
import {
  LegacyNavigationTarget,
  NavigationTarget,
} from "@cq/app/core/navigation/models/target";
import { PolicyCenterRoutingService } from "@cq/app/core/routing/services/policycenter-routing.service";
import { AccountStoreService } from "../stores/account-store.service";

export function legacyAccountNavigationFactory(
  $injector: angular.auto.IInjectorService,
) {
  return $injector.get<LegacyAccountNavigationService>("CQAccountNavigation");
}

export const legacyAccountNavigationProvider = {
  provide: LegacyAccountNavigationService,
  useFactory: legacyAccountNavigationFactory,
  deps: ["$injector"],
};

interface AccountInformationNavigationOptions {
  openAddressModalOnLoad?: boolean;
  openNamedInsuredModalOnLoad?: boolean;
  params?: Params;
  reload?: boolean;
  reloadAccount?: boolean;
  allowDeactivation?: boolean;
}

@Injectable({
  providedIn: "root",
})
export class AccountNavigationRouterService {
  private readonly legacyAccountNavigation = inject(
    LegacyAccountNavigationService,
  );
  private readonly accountNavigation = inject(AccountNavigationService);
  private readonly accountStore = inject(AccountStoreService);
  private readonly policyCenter = inject(PolicyCenterRoutingService);

  isAccountActive() {
    return (
      this.legacyAccountNavigation.isAccountActive() ||
      this.accountNavigation.isAccountActive()
    );
  }

  isAccountAddProductsActive() {
    return this.accountNavigation.isAccountAddProductsActive();
  }

  isAccountInformationActive() {
    return this.accountNavigation.isAccountInformationActive();
  }

  isAccountInformation(target: NavigationTarget) {
    if (target instanceof LegacyNavigationTarget) {
      return false;
    } else {
      return this.accountNavigation.isAccountInformation(target.snapshot);
    }
  }

  isAccountQuestionsActive() {
    return this.accountNavigation.isAccountQuestionsActive();
  }

  isAccountQuestions(target: NavigationTarget) {
    if (target instanceof LegacyNavigationTarget) {
      return false;
    } else {
      return this.accountNavigation.isAccountQuestions(target.snapshot);
    }
  }

  navigateToAccountInformation(
    accountNumber?: string,
    options?: AccountInformationNavigationOptions,
  ) {
    const account = this.accountStore.account();
    const isFarmAccountNumber = accountNumber?.startsWith("FPK") ?? false;
    const isFarmAccountStored = account?.type === "farm";
    const isAccountTypeParamFarm =
      !accountNumber && !account && options?.params?.accountType === "farm";
    const isFarmAccount =
      isFarmAccountNumber || isFarmAccountStored || isAccountTypeParamFarm;

    if (isFarmAccount) {
      this.policyCenter.routeToAccount(accountNumber ?? account?.accountNumber);
      return Promise.resolve(false);
    }

    return this.accountNavigation.navigateToAccountInformation(
      accountNumber,
      options?.params,
      {
        allowDeactivation: options?.allowDeactivation,
        openAddAddress: options?.openAddressModalOnLoad,
        openAddNamedInsured: options?.openNamedInsuredModalOnLoad,
      },
    );
  }

  navigateToAccountQuestions(accountNumber?: string) {
    // do not attempt to navigate without a provided account number
    if (!accountNumber) {
      return Promise.resolve(false);
    }

    return this.accountNavigation.navigateToAccountQuestions(accountNumber);
  }

  navigateToAddProducts(accountNumber?: string) {
    // do not attempt to navigate without a provided account number
    if (!accountNumber) {
      return Promise.resolve(false);
    }

    return this.accountNavigation.navigateToAccountAddProducts(accountNumber);
  }
}

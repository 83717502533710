import { SystemPermissionType } from "@cq/generated/edge/gateway/user.handler";

export interface User {
  displayName: string;
  email: string;
  firstName: string;
  group: string;
  helpDeskNumber: string;
  id: string;
  isActive: boolean;
  isExternal: boolean;
  lastName: string;
  permissions: Permission[];
  roles: string[];
  type: string;
}

export interface Permission {
  available: boolean;
  name: SystemPermissionType;
}

export const DEFAULT_HELP_DESK_NUMBER = "(888) 667-3866";

export const INVALID_USER: User = {
  displayName: "",
  email: "",
  firstName: "",
  group: "",
  helpDeskNumber: DEFAULT_HELP_DESK_NUMBER,
  id: "",
  isActive: false,
  isExternal: false,
  lastName: "",
  permissions: [],
  roles: [],
  type: "",
};

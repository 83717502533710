import { inject, Injectable } from "@angular/core";
import { BopBuildingNavigationService } from "./bop-building-navigation.service";
import { Building } from "../../models/building";
import { LegacyBusinessownersBuildingNavigationService } from "@legacy/nationwide/js/src/edge/quoteandbind/bop/building/building-navigation.service";
import {
  Page,
  PageUpgradeService,
} from "@cq/app/core/environment/services/page-upgrade.service";
import { PolicyTransaction } from "@cq/app/policy/models/policy";
import { PolicyTransactionStoreService } from "@cq/app/policy/services/policy-transaction-store.service";

export function legacyBusinessownersBuildingNavigationFactory(
  $injector: angular.auto.IInjectorService,
) {
  return $injector.get<LegacyBusinessownersBuildingNavigationService>(
    "CQBusinessownersBuildingNavigation",
  );
}

export const legacyBusinessownersBuildingNavigationProvider = {
  provide: LegacyBusinessownersBuildingNavigationService,
  useFactory: legacyBusinessownersBuildingNavigationFactory,
  deps: ["$injector"],
};

@Injectable({
  providedIn: "root",
})
export class BopBuildingNavigationRouterService {
  #buildingNavigation = inject(BopBuildingNavigationService);
  #legacyBuildingNavigation = inject(
    LegacyBusinessownersBuildingNavigationService,
  );
  #pageUpgrade = inject(PageUpgradeService);
  #transactionStore = inject(PolicyTransactionStoreService);

  navigateToBuildingStart(
    transaction?: string | PolicyTransaction,
    building?: string | Building,
  ): Promise<boolean> {
    const policyTransaction = this.getPolicyTransaction(transaction);
    const buildingId = this.getBuildingId(building);
    if (this.#pageUpgrade.isUpgraded(Page.BOP_BUILDING_FLOW)) {
      const buildingId = this.getBuildingId(building);
      return this.#buildingNavigation.navigateToLocation(
        policyTransaction,
        buildingId,
      );
    } else {
      return this.#legacyBuildingNavigation.navigateToAddress(
        policyTransaction?.id,
        buildingId,
      );
    }
  }

  navigateToDetails(
    transaction?: string | PolicyTransaction,
    building?: string | Building,
  ): Promise<boolean> {
    const policyTransaction = this.getPolicyTransaction(transaction);
    const buildingId = this.getBuildingId(building);
    if (this.#pageUpgrade.isUpgraded(Page.BOP_BUILDING_FLOW)) {
      return this.#buildingNavigation.navigateToDetails(
        policyTransaction,
        buildingId,
      );
    } else {
      return this.#legacyBuildingNavigation.navigateToDetails(
        policyTransaction?.id,
        buildingId,
      );
    }
  }

  private getPolicyTransaction(transaction?: string | PolicyTransaction) {
    return typeof transaction === "string"
      ? this.#transactionStore.transactions().find((t) => t.id === transaction)
      : transaction;
  }

  private getBuildingId(building?: string | Building) {
    if (!building) {
      return undefined;
    }
    return typeof building === "string" ? building : building.id;
  }
}

import { computed, inject, Injectable, linkedSignal } from "@angular/core";
import { ModeService } from "../mode.service";
import { merge } from "lodash";

export interface Settings {
  idleTimeout: boolean;
}

interface SettingAccessor<T> {
  get(): T | undefined;
  set(value: T): void;
}

class BooleanSettingAccessor implements SettingAccessor<boolean> {
  constructor(
    private readonly key: string,
    private readonly defaultValue: boolean,
  ) {}

  get() {
    const stored = localStorage.getItem(this.key);
    return stored === null ? this.defaultValue : stored === "true";
  }

  set(value: boolean) {
    if (value === this.defaultValue) {
      localStorage.removeItem(this.key);
    } else {
      localStorage.setItem(this.key, value.toString());
    }
  }

  getDefault() {
    return this.defaultValue;
  }
}

enum Setting {
  ENABLED_IDLE_TIMEOUT = "cq.setting.enable-idle-timeout",
}

const SETTINGS = {
  [Setting.ENABLED_IDLE_TIMEOUT]: new BooleanSettingAccessor(
    Setting.ENABLED_IDLE_TIMEOUT,
    true,
  ),
};

const DEFAULT_SETTINGS: Settings = {
  idleTimeout: SETTINGS[Setting.ENABLED_IDLE_TIMEOUT].getDefault(),
};

@Injectable({
  providedIn: "root",
})
export class SettingsService {
  #mode = inject(ModeService);
  #settings = linkedSignal(() => this.load());
  settings = this.#settings.asReadonly();

  // idle timeout
  isIdleTimeoutEnabled = computed(() => this.#settings().idleTimeout);
  setIsIdleTimeoutEnabled(enabled: boolean) {
    this.#mode.guard(() => {
      this.#settings.update((settings) => ({
        ...settings,
        idleTimeout: enabled,
      }));
      SETTINGS[Setting.ENABLED_IDLE_TIMEOUT].set(enabled);
    });
  }

  persist(settings: Settings) {
    this.setIsIdleTimeoutEnabled(settings.idleTimeout);
  }

  private load(): Settings {
    if (this.#mode.isProduction()) {
      return DEFAULT_SETTINGS;
    }

    const user: Partial<Settings> = {
      idleTimeout: SETTINGS[Setting.ENABLED_IDLE_TIMEOUT].get(),
    };
    return merge({}, DEFAULT_SETTINGS, user);
  }
}

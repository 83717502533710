import { Injectable, inject } from "@angular/core";
import { NavigationExtras, Router } from "@angular/router";
import { EXACT } from "@cq/app/core/routing/match-option";
import { RoutingService } from "@cq/app/core/routing/services/routing.service";
import { map } from "rxjs";
import { BuildingFlow } from "../services/bop-active-building.service";
import { PolicyTransaction } from "@cq/app/policy/models/policy";
import { ActivePolicyStoreService } from "@cq/app/policy/stores/active-policy-store.service";
import { Building } from "../../models/building";

function buildBuildingRoute(
  transaction?: PolicyTransaction,
  buildingId?: string,
) {
  const root = `/upgrade/policy-transaction/${transaction?.id}/businessowners/building`;
  if (!buildingId) {
    return root;
  }
  return `${root}/${buildingId}`;
}

function extras(flow: BuildingFlow) {
  const extras: NavigationExtras = {};
  if (flow === BuildingFlow.ADD) {
    extras.fragment = "flow=ADD";
  }
  return extras;
}

abstract class BuildingNavigator {
  isAddressActive$ = this.routing.navigationChanges$.pipe(
    map(() => this.isLocationActive()),
  );

  isDetailsActive$ = this.routing.navigationChanges$.pipe(
    map(() => this.isDetailsActive()),
  );

  isCoveragesActive$ = this.routing.navigationChanges$.pipe(
    map(() => this.isCoveragesActive()),
  );

  isQuestionsActive$ = this.routing.navigationChanges$.pipe(
    map(() => this.isQuestionsActive()),
  );

  constructor(private readonly routing: RoutingService) {}

  abstract isLocationActive(): boolean;
  abstract isDetailsActive(): boolean;
  abstract isCoveragesActive(): boolean;
  abstract isQuestionsActive(): boolean;

  abstract get isDetailsAvailable(): boolean;
  abstract get isCoveragesAvailable(): boolean;
  abstract get isQuestionsAvailable(): boolean;

  abstract navigateToLocation(): Promise<boolean>;
  abstract navigateToDetails(): Promise<boolean>;
  abstract navigateToCoverages(): Promise<boolean>;
  abstract navigateToQuestions(): Promise<boolean>;
}

class ExistingBuildingNavigator extends BuildingNavigator {
  isDetailsAvailable = true;
  isCoveragesAvailable = true;
  isQuestionsAvailable = true;

  constructor(
    private readonly building: Building,
    private readonly flow: BuildingFlow,
    private readonly navigation: BopBuildingNavigationService,
    routing: RoutingService,
    private readonly activePolicy: ActivePolicyStoreService,
  ) {
    super(routing);
  }

  isLocationActive() {
    return this.navigation.isLocationActive(this.building.id);
  }

  isDetailsActive() {
    return this.navigation.isDetailsActive(this.building.id);
  }

  isCoveragesActive() {
    return this.navigation.isCoveragesActive(this.building.id);
  }

  isQuestionsActive() {
    return this.navigation.isQuestionsActive(this.building.id);
  }

  navigateToLocation() {
    return this.navigation.navigateToLocation(
      this.activePolicy.policyTransaction(),
      this.building.id,
      this.flow,
    );
  }

  navigateToDetails() {
    return this.navigation.navigateToDetails(
      this.activePolicy.policyTransaction(),
      this.building.id,
      this.flow,
    );
  }

  navigateToCoverages() {
    return this.navigation.navigateToCoverages(
      this.activePolicy.policyTransaction(),
      this.building.id,
      this.flow,
    );
  }

  navigateToQuestions() {
    return this.navigation.navigateToQuestions(
      this.activePolicy.policyTransaction(),
      this.building.id,
      this.flow,
    );
  }
}

class NewBuildingNavigator extends BuildingNavigator {
  isDetailsAvailable = false;
  isCoveragesAvailable = false;
  isQuestionsAvailable = false;

  constructor(
    private readonly navigation: BopBuildingNavigationService,
    routing: RoutingService,
  ) {
    super(routing);
  }

  isLocationActive() {
    return this.navigation.isLocationActive();
  }

  isDetailsActive() {
    return false;
  }

  isCoveragesActive() {
    return false;
  }

  isQuestionsActive() {
    return false;
  }

  navigateToLocation() {
    return Promise.resolve(false);
  }

  navigateToDetails() {
    return Promise.resolve(false);
  }

  navigateToCoverages() {
    return Promise.resolve(false);
  }

  navigateToQuestions() {
    return Promise.resolve(false);
  }
}

@Injectable({
  providedIn: "root",
})
export class BopBuildingNavigationService {
  #activePolicy = inject(ActivePolicyStoreService);
  #router = inject(Router);
  #routing = inject(RoutingService);

  navigator(building?: Building, flow = BuildingFlow.ADD): BuildingNavigator {
    if (building) {
      return new ExistingBuildingNavigator(
        building,
        flow,
        this,
        this.#routing,
        this.#activePolicy,
      );
    }
    return new NewBuildingNavigator(this, this.#routing);
  }

  // Active

  isLocationActive(buildingId?: string) {
    return this.#router.isActive(
      `${buildBuildingRoute(this.#activePolicy.policyTransaction(), buildingId)}/location`,
      EXACT,
    );
  }

  isDetailsActive(buildingId?: string) {
    return this.#router.isActive(
      `${buildBuildingRoute(this.#activePolicy.policyTransaction(), buildingId)}/details`,
      EXACT,
    );
  }

  isCoveragesActive(buildingId?: string) {
    return this.#router.isActive(
      `${buildBuildingRoute(this.#activePolicy.policyTransaction(), buildingId)}/coverages`,
      EXACT,
    );
  }

  isQuestionsActive(buildingId?: string) {
    return this.#router.isActive(
      `${buildBuildingRoute(this.#activePolicy.policyTransaction(), buildingId)}/questions`,
      EXACT,
    );
  }

  // Navigation

  navigateToLocation(
    transaction?: PolicyTransaction,
    buildingId?: string,
    buildingFlow?: BuildingFlow,
  ) {
    if (!transaction) {
      return Promise.resolve(false);
    }

    const flow = buildingId ? BuildingFlow.EDIT : BuildingFlow.ADD;
    return this.#router.navigate(
      [`${buildBuildingRoute(transaction, buildingId)}/location`],
      extras(buildingFlow ?? flow),
    );
  }

  navigateToDetails(
    transaction?: PolicyTransaction,
    buildingId?: string,
    flow: BuildingFlow = BuildingFlow.EDIT,
  ) {
    if (!transaction) {
      return Promise.resolve(false);
    }

    return this.#router.navigate(
      [`${buildBuildingRoute(transaction, buildingId)}/details`],
      extras(flow),
    );
  }

  navigateToCoverages(
    transaction?: PolicyTransaction,
    buildingId?: string,
    flow: BuildingFlow = BuildingFlow.EDIT,
  ) {
    if (!transaction) {
      return Promise.resolve(false);
    }

    return this.#router.navigate(
      [`${buildBuildingRoute(transaction, buildingId)}/coverages`],
      extras(flow),
    );
  }

  navigateToQuestions(
    transaction?: PolicyTransaction,
    buildingId?: string,
    flow: BuildingFlow = BuildingFlow.EDIT,
  ) {
    if (!transaction) {
      return Promise.resolve(false);
    }

    return this.#router.navigate(
      [`${buildBuildingRoute(transaction, buildingId)}/questions`],
      extras(flow),
    );
  }
}

import { inject, Injectable } from "@angular/core";
import {
  Page,
  PageUpgradeService,
} from "@cq/app/core/environment/services/page-upgrade.service";
import { PolicyTransaction } from "@cq/app/policy/models/policy";
import { Validation } from "@cq/app/policy/models/validation";
import { ValidationNavigationStrategy } from "@cq/app/policy/modules/validation";
import { BopNavigationService } from "../navigation/bop-navigation.service";

const SUPPORTED_VALIDATION_IDS = [
  "BOP.VBR_027",
  "BOP.VBR_103",
  "BOP.VBR_167",
  "BOP.VBR_099",
];

@Injectable()
export class BusinessownersInformationStrategyService
  implements ValidationNavigationStrategy
{
  #navigation = inject(BopNavigationService);
  #upgrade = inject(PageUpgradeService);

  supports(validation: Validation): boolean {
    if (validation.id === undefined) {
      return false;
    }

    const isBusinessownersInformationUpgraded = this.#upgrade.isUpgraded(
      Page.BOP_INFORMATION,
    );
    return (
      isBusinessownersInformationUpgraded &&
      SUPPORTED_VALIDATION_IDS.includes(validation.id)
    );
  }

  navigate(
    transaction: PolicyTransaction,
    _validation: Validation,
  ): Promise<boolean> {
    return this.#navigation.navigateToBOPInformation(transaction);
  }
}

import { BUSINESSOWNERS, Product, UMBRELLA } from "@cq/app/core/model/product";
import { umbrellaValidationStrategies } from "./umbrella/validation";
import { InjectionToken, Type } from "@angular/core";
import { ValidationNavigationStrategy } from "../modules/validation";
import { businessownersValidationStrategies } from "./businessowners/validation";

interface ProductValidationStrategies {
  product: Product;
  strategies: Type<ValidationNavigationStrategy>[];
}

export const PRODUCT_VALIDATION_STRATEGIES = new InjectionToken<
  ProductValidationStrategies[]
>("PRODUCT_VALIDATION_STRATEGIES", {
  factory: () => [
    { product: BUSINESSOWNERS, strategies: businessownersValidationStrategies },
    { product: UMBRELLA, strategies: umbrellaValidationStrategies },
  ],
});

import { Component, inject } from "@angular/core";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { ButtonModule } from "@cq/app/shared/button/button.module";
import { DialogModule } from "@cq/app/shared/dialog/dialog.module";
import { Dialog } from "@cq/app/shared/dialog/model/dialog";
import { IconModule } from "@cq/app/shared/icon/icon.module";
import { FormsModule } from "@angular/forms";
import { SettingsService } from "@cq/app/core/environment/services/settings.service";

@Component({
  selector: "cq-settings-dialog",
  imports: [
    ButtonModule,
    DialogModule,
    FormsModule,
    IconModule,
    MatSlideToggleModule,
  ],
  templateUrl: "./settings-dialog.component.html",
  styleUrl: "./settings-dialog.component.scss",
})
export class SettingsDialogComponent extends Dialog<
  SettingsDialogComponent,
  void
> {
  #settings = inject(SettingsService);
  settings = { ...this.#settings.settings() }; // shallow copy

  cancel() {
    this.dialog.close();
  }

  save() {
    this.#settings.persist(this.settings);
    this.dialog.close();
  }
}

<cq-dialog>
  <cq-icon-symbol cq-dialog-icon name="settings"></cq-icon-symbol>
  <cq-dialog-title>Settings</cq-dialog-title>
  <cq-dialog-content>
    <div class="setting">
      <label for="cq-settings-idle-timeout">Idle Timeout</label>
      <mat-slide-toggle
        id="cq-settings-idle-timeout"
        [(ngModel)]="settings.idleTimeout"
      ></mat-slide-toggle>
    </div>
  </cq-dialog-content>
  <cq-dialog-actions>
    <cq-button (click)="cancel()">
      <cq-icon-font name="cancel"></cq-icon-font>
      Cancel
    </cq-button>
    <cq-dialog-action-spacer></cq-dialog-action-spacer>
    <cq-button (click)="save()">
      <cq-icon-font name="check"></cq-icon-font>
      Save
    </cq-button>
  </cq-dialog-actions>
</cq-dialog>

<mat-sidenav-container>
  <mat-sidenav mode="over">
    <mat-action-list class="actions">
      <button mat-list-item (click)="configuration()">
        <span class="icon-action">
          <cq-icon-font name="rule"></cq-icon-font>
          Page Upgrade Status
        </span>
      </button>
      <button mat-list-item (click)="mockup()">
        <span class="icon-action">
          <cq-icon-font name="view_quilt"></cq-icon-font>
          Mockup
        </span>
      </button>
      <button mat-list-item (click)="settings()">
        <span class="icon-action">
          <cq-icon-symbol name="settings"></cq-icon-symbol>
          Settings
        </span>
      </button>
    </mat-action-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>

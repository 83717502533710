import { inject } from "@angular/core";
import { CanActivateFn, RedirectCommand, Router } from "@angular/router";
import { catchError, map, of, OperatorFunction, tap } from "rxjs";
import { UserService } from "../services/user.service";
import { setUserProperties } from "@cq/app/core/init/walkme";

export const userGuard: CanActivateFn = () => {
  const user = inject(UserService);
  const router = inject(Router);
  return guard(user, () =>
    of(new RedirectCommand(router.createUrlTree(["/unauthorized"]))),
  );
};

export const legacyUserGuard = (user: UserService, router: Router) =>
  guard(user, () => {
    router.navigate(["/unauthorized"], { info: { allowDeactivation: true } });
    return of(false);
  });

function guard<T, R>(user: UserService, errorHandler: OperatorFunction<T, R>) {
  return user.retrieveCurrentUser().pipe(
    tap(setUserProperties),
    map((user) => user.isActive),
    catchError(errorHandler),
  );
}

import { inject, provideEnvironmentInitializer } from "@angular/core";
import {
  Configuration,
  IDLE_CONFIGURATION,
  IdleService,
} from "./services/idle.service";

const DEFAULT_CONFIGURATION: Configuration = {
  idleTime: 15 * 60 * 1000, // 15 minutes
};

export function provideIdleMonitoring(
  config: Configuration = DEFAULT_CONFIGURATION,
) {
  return [
    { provide: IDLE_CONFIGURATION, useValue: config },
    IdleService,
    provideEnvironmentInitializer(() => inject(IdleService)),
  ];
}

import { setAngularJSGlobal, UpgradeModule } from "@angular/upgrade/static";

import { init } from "./app/core/init/walkme";

import angular from "angular";
import "angular-translate";
import "angular-i18n/angular-locale_en-us";
import { ApplicationInitEvent } from "./app/core/diagnostic/event";
import { bootstrapApplication } from "@angular/platform-browser";
import { AppComponent } from "./app/app.component";
import { ConfigService } from "./app/core/environment/services/config.service";
import { AuthService } from "./app/core/auth/services/auth.service";
import { EventLogService, provideDiagnosticMonitoring } from "./app/diagnostic";
import { WindowService } from "./app/core/system/services/window.service";
import {
  importProvidersFrom,
  inject,
  provideAppInitializer,
} from "@angular/core";
import { NavigationService } from "./app/core/navigation/services/navigation.service";
import { LoaderService } from "./app/core/init/loader.service";
import { MAT_DIALOG_DEFAULT_OPTIONS } from "@angular/material/dialog";
import { AccountContextService } from "./app/account/diagnostic";
import {
  IdentityContextService,
  PageContextService,
  SessionContextService,
  VisibilityContextService,
} from "./app/core/diagnostic";
import { PolicyTransactionContextService } from "./app/policy/diagnostic";
import { FullstoryProcessorService } from "./app/diagnostic/processor/fullstory-processor.service";
import { LoggerProcessorService } from "./app/diagnostic/processor/logger-processor.service";
import { SplunkProcessorService } from "./app/diagnostic/processor/splunk-processor.service";
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from "@angular/common/http";
import { provideAnimations } from "@angular/platform-browser/animations";
import { provideAuthConfig } from "./app/core/auth";
import { provideAppRouting } from "./app/app-routing";
import { provideLegacyProviders } from "./app/legacy-provider";
import { provideIdleMonitoring } from "./app/core/idle";

setAngularJSGlobal(angular);

bootstrapApplication(AppComponent, {
  providers: [
    // await application configuration
    provideAppInitializer(() => {
      const initializerFn = (
        (configService: ConfigService) => () =>
          configService.app$
      )(inject(ConfigService));
      return initializerFn();
    }),
    // monitor navigation
    provideAppInitializer(() => {
      const initializerFn = (
        (navigation: NavigationService) => () =>
          navigation.monitor()
      )(inject(NavigationService));
      return initializerFn();
    }),
    // present loading message
    provideAppInitializer(() => {
      const initializerFn = (
        (loader: LoaderService) => () =>
          loader.start()
      )(inject(LoaderService));
      return initializerFn();
    }),
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        disableClose: true,
        maxWidth: "unset",
        panelClass: "cq-dialog-panel",
        // https://github.com/angular/components/issues/28096
        autoFocus: "first-tabbable",
        delayFocusTrap: true,
        restoreFocus: true,
      },
    },
    importProvidersFrom(UpgradeModule),
    provideAnimations(),
    provideAppRouting(),
    provideAuthConfig(),
    provideDiagnosticMonitoring({
      contextualizers: [
        AccountContextService,
        IdentityContextService,
        PageContextService,
        PolicyTransactionContextService,
        SessionContextService,
        VisibilityContextService,
      ],
      processors: [
        FullstoryProcessorService,
        LoggerProcessorService,
        SplunkProcessorService,
      ],
    }),
    provideIdleMonitoring(),
    provideHttpClient(withInterceptorsFromDi()),
    provideLegacyProviders(),
  ],
})
  .then((ref) => {
    const injector = ref.injector;

    const auth = injector.get(AuthService);
    const config = injector.get(ConfigService);
    const event = injector.get(EventLogService);
    const upgrade = injector.get(UpgradeModule);
    const window = injector.get(WindowService);

    auth.login().subscribe((identity) => {
      if (identity) {
        init(config.app.walkMeUrl);
      }

      const origin = window.origin();
      event.log(new ApplicationInitEvent(origin.time, origin.navigation));

      import("@legacy/nationwide/app/app").then(() => {
        upgrade.bootstrap(
          document.documentElement,
          ["nationwide.quoteandbind.app"],
          {
            strictDi: true,
          },
        );
      });
    });
  })
  .catch((err) => console.error(err));

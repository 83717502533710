<cq-dialog>
  <cq-dialog-icon type="svg" name="exclamation-red"></cq-dialog-icon>
  <cq-dialog-title>Please Confirm</cq-dialog-title>
  <cq-dialog-subtitle>
    Your session will close in 5 minutes and information may be lost.
    <br />
    Press OK to continue your session.
  </cq-dialog-subtitle>
  <cq-dialog-actions>
    <cq-button (click)="continue()">
      <cq-icon-font name="check"></cq-icon-font>
      OK
    </cq-button>
  </cq-dialog-actions>
</cq-dialog>

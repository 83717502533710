import { Component, computed, HostBinding, input } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";

// updates to these types must be aligned with the request parameters for the symbols that are within the index.hmtl
type IconName =
  | "add_circle"
  | "arrow_circle_left"
  | "arrow_circle_right"
  | "cancel"
  | "check_circle"
  | "delete"
  | "settings";
type Style = "outlined";
type Weight = 100 | 200 | 300 | 400 | 500 | 600 | 700;
type Grade = 0;
type OpticalSize = 24;

const FONT_SETS = new Map<string, string>([["outlined", "symbols-outlined"]]);
const DEFAULT_FONT_SET = "symbols-outlined";

@Component({
  selector: "cq-icon-symbol",
  imports: [MatIconModule],
  templateUrl: "./icon-symbol.component.html",
  styleUrl: "./icon-symbol.component.scss",
  host: {
    "[style.--cq-icon-symbol-fill]": "fillValue()",
    "[style.--cq-icon-symbol-grade]": "grade()",
    "[style.--cq-icon-symbol-weight]": "weight()",
    "[style.--cq-icon-symbol-optical-size]": "opticalSize()",
  },
})
export class IconSymbolComponent {
  fill = input<boolean>();
  grade = input<Grade>();
  name = input.required<IconName>();
  opticalSize = input<OpticalSize>();
  style = input<Style>("outlined");
  weight = input<Weight>();

  fillValue = computed(() => {
    const fill = this.fill();
    switch (fill) {
      case undefined:
        return undefined;
      case true:
        return 1;
      case false:
        return 0;
    }
  });
  fontSet = computed(() => FONT_SETS.get(this.style()) ?? DEFAULT_FONT_SET);

  @HostBinding("class.cq-icon") readonly baseClass = true;
}
